export const MERCOSUL_PLATES = {
  /** @Ex AB123CD */
  ARGENTINA: /^[A-Z]{2}\d{3}[A-Z]{2}$/,

  /** @Ex ABC1D23 */
  BRASIL: /^[A-Z]{3}\d[A-Z]\d\d$/,

  /** @Ex ABC1234 */
  BOLIVIA: /^[A-Z]{3}\d{4}$/,

  /** @Ex ABC1234 */
  PARAGUAY: /^[A-Z]{3}\d{4}$/,

  /** @Ex ABC1234 */
  URUGUAY: /^[A-Z]{3}\d{4}$/,

  /** @eX AB1234CD */
  VENEZUELA: /^[A-Z]{2}\d{4}[A-Z]{2}$/
}

export const OTHER_PLATES = {
  /** @Ex ABC1234 */
  BRASIL_OLD: /^[A-Z]{3}\d{4}$/,

  /** @Ex ABC1234 */
  BOLIVIA_OLD: /^[A-Z]{3}\d{3}$/,

  /** @Ex AB1234 */
  CHILE_OLD: /^[A-Z]{2}\d{4}$/,

  /** @Ex ABCD12 */
  CHILE: /^[A-Z]{4}\d{2}$/,

  /** @Ex ABCD123 */
  PARAGUAY_OLD: /^[A-Z]{4}\d{3}$/,

  /** @Ex ABC1234 */
  URUGUAY_OLD: /^[A-Z]{3}\d{4}$/,
  
  /** @eX ABC12D or ABC123  */
  VENEZUELA_OLD: /^[A-Z]{3}\d{2}[A-Z]?$|^[A-Z]{3}\d{3}$/
}